/* eslint-disable react/jsx-key */
import chatIcon from "../Assets/icons/Chat-Floating Button.svg";
import cancelIcon from "../Assets/icons/cancel.png";
import chatBotIcon from "../Assets/icons/Poltu.png";
// import chaticon2 from "../Assets/icons/Chat-Floating Button2.svg";
// import React from "react"; 
export const REPLY_MESSAGE =
  "I apologize, but I am unable to find any relevant information for your query. Please try rephrasing your question or providing more details.";

export const GREETING_MESSAGE =
"Hello! How can I assist you today?";

export const HEADING="NEWS18";
export const LOK_SABHA_HEADING = "Lok Sabha Chatbot";
export const ASSEMBLY_HEADING = "Assembly Chatbot";
export const HEADING3="Poltu";
export const CHATBOT_HEADING="ELECTION";
export const CHATBOT_SUBHEADING="ChatBot";
export const HEADING_TEXT_COLOUR="White";

export const LOK_SABHA_INTRO_MESSAGE = {
    type: "options2",
    content: {
      options: [
        {
          text: (
            <>
              <span style={{ fontWeight: "bold", color:"#4A4A4A" }}>
                Welcome to our Elections Chatbot!
              </span>{" "}
              Hi! I am Poltu, the News18 Election Chatbot. Ask me questions on the Lok Sabha elections from 2009 to 2024 and I will answer them.
            </>
          ),
        },
        {
          text: (
            <>
              <span style={{ fontWeight: "bold", color:"#4A4A4A"}}>
                Feel free to ask questions like:
              </span>
            </>
          ),
        },
      ],
    },
  };

  export const CNBC_TV18_INTRO_MESSAGE = {
    type: "options2",
    content: {
      options: [
        {
          text: (
            <>
              <span style={{ fontWeight: "bold", color:"#4A4A4A" }}>
                Welcome to our Elections Chatbot!
              </span>{" "}
              Hi! I am Poltu, the CNBC-TV18 Election Chatbot. Ask me questions on the Lok Sabha elections from 2009 to 2024 and I will answer them.
            </>
          ),
        },
        {
          text: (
            <>
              <span style={{ fontWeight: "bold", color:"#4A4A4A"}}>
                Feel free to ask questions like:
              </span>
            </>
          ),
        },
      ],
    },
  };

  export const CNBC_AWAAZ_INTRO_MESSAGE = {
    type: "options2",
    content: {
      options: [
        {
          text: (
            <>
              <span style={{ fontWeight: "bold", color:"#4A4A4A" }}>
                Welcome to our Elections Chatbot!
              </span>{" "}
              Hi! I am Poltu, the CNBC Awaaz Election Chatbot. Ask me questions on the Lok Sabha elections from 2009 to 2024 and I will answer them.
            </>
          ),
        },
        {
          text: (
            <>
              <span style={{ fontWeight: "bold", color:"#4A4A4A"}}>
                Feel free to ask questions like:
              </span>
            </>
          ),
        },
      ],
    },
  };
  export const ASSEMBLY_INTRO_MESSAGE = {
    type: "options2",
    content: {
      options: [
        {
          text: (
            <>
              <span style={{ fontWeight: "bold", color:"#4A4A4A" }}>
                Welcome to our Elections Chatbot!
              </span>{" "}
              Hi! I am Poltu, the News18 Election Chatbot. Ask me questions on the Andhra Pradesh, Odisha, Arunachal Pradesh and Sikkim Assembly elections from 2009 to 2024 and I will answer them.
            </>
          ),
        },
        {
          text: (
            <>
              <span style={{ fontWeight: "bold", color:"#4A4A4A"}}>
                Feel free to ask questions like:
              </span>
            </>
          ),
        },
      ],
    },
  };
  
  
export const INITIAL_MESSAGES = [
];

export const LOK_SABHA_INITIAL_QUESTION = {
    type: "options",
    content: {
        options: [
            {
                text: "Who won by the highest margin of votes in the 2019 Lok Sabha elections?",
                value: "Who won by the highest margin of votes in the 2019 Lok Sabha elections?",
            },
            {
                text: "How many seats did the BJP win in the 2014 Lok Sabha elections?",
                value: "How many seats did the BJP win in the 2014 Lok Sabha elections?",
            },
            {
                text: "Which constituency recorded the highest voter turnout in the 2009 Lok Sabha elections?",
                value: "Which constituency recorded the highest voter turnout in the 2009 Lok Sabha elections?",
            },
        ],
    },
};

export const ASSEMBLY_INITIAL_QUESTIONS = {
  type: "options",
  content: {
      options: [
          {
              text: "How many seats did the TDP win the 2019 Andhra Pradesh Assembly elections?",
              value: "How many seats did the TDP win the 2019 Andhra Pradesh Assembly elections?",
          },
          {
              text: "Who won by the highest margin in the 2019 Odisha Assembly elections?",
              value: "Who won by the highest margin in the 2019 Odisha Assembly elections?",
          },
          {
              text: "How many Assembly constituencies are there in Sikkim?",
              value: "How many Assembly constituencies are there in Sikkim?",
          },
      ],
  },
};

export const CHATBOT_BETA_MESSAGE=" Chatbot is in Beta. Verify chatbot election information for accuracy.";

export const GREETINGS = ["hi", "hi?","hello", "hey", "hi there", "hello there", "hey there", "hi, there", "hello, there", "hey, there","howdy", "greetings", "what's up", "how are you?", "good day", "yo", "hiya", "sup", "wassup"];

export const ASSETS={chatBotIcon, chatIcon, cancelIcon};

