import { React, useEffect, useState, useCallback } from "react";
import Chat, { Bubble, useMessages } from "@chatui/core";
import "@chatui/core/dist/index.css";
import axios from "axios";
import { motion } from "framer-motion";
import chatstyle from "../components/chat.module.css";
import {
  REPLY_MESSAGE,
  GREETING_MESSAGE,
  LOK_SABHA_INITIAL_QUESTION,
  CHATBOT_BETA_MESSAGE,
  INITIAL_MESSAGES,
  HEADING,
  HEADING_TEXT_COLOUR,
  ASSETS,
  LOK_SABHA_INTRO_MESSAGE,
  HEADING3,
  // CHATBOT_HEADING,
  // CHATBOT_SUBHEADING,
  GREETINGS,
  ASSEMBLY_INITIAL_QUESTIONS,
  ASSEMBLY_INTRO_MESSAGE,
  ASSEMBLY_HEADING, LOK_SABHA_HEADING,
  CNBC_TV18_INTRO_MESSAGE,
  CNBC_AWAAZ_INTRO_MESSAGE
  
} from "../Config/constant";
import image from "../Assets/icons/bg@2x.jpg";
import cnbcchaticon from "../Assets/icons/Chat-Floating Button copy.svg";
import user from "../Assets/icons/Group 32.svg";
import user2 from "../Assets/icons/Group 1708.svg";
import user3 from "../Assets/icons/group3.svg";
import ReactGA from "react-ga4";
 
const ChatWidget = (props) => {
  
  const { urlApi, headersApi, sessionId } = props;
  const { chatBotIcon, chatIcon, cancelIcon } = ASSETS;
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [sampleText, setSampleText] = useState("");
  const [apiInProgress, setApiInProgress] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  // const [showText, setShowText] = useState(false);
  const [showFirstBubble, setShowFirstBubble] = useState(false);
  const [showFirstText, setShowFirstText] = useState(false);
  const [showSecondBubble, setShowSecondBubble] = useState(false);
  const [showSecondText, setShowSecondText] = useState(false);
      const [showCloseButton, setShowCloseButton] = useState(false);
  const [areBubblesVisible, setAreBubblesVisible] = useState(true);

  const chatBotIcons = headersApi["App-Id"] === "fa6b02f6-0906-cnbc-11ef-poltu-ac73-ce427119df63" ? cnbcchaticon : 
  headersApi["App-Id"] === "ga9b02f6-2310-cnbc-awaaz-11ef-poltu-td73-ce427000df63" ? cnbcchaticon : 
  chatIcon;

  const initialMessagesFormatted = INITIAL_MESSAGES.map((x) => {

    return {
      type: "text",
      content: { text: x }
    };
  });
  if (headersApi && headersApi.Pathname && headersApi.Pathname === "/assembly"){
    initialMessagesFormatted.push(ASSEMBLY_INTRO_MESSAGE);
    initialMessagesFormatted.push(ASSEMBLY_INITIAL_QUESTIONS);
  }else{
    // setShowChat(false);
    if (headersApi && headersApi["App-Id"]) {
      if (headersApi["App-Id"] === "ga9b02f6-2310-cnbc-awaaz-11ef-poltu-td73-ce427000df63") {
        initialMessagesFormatted.push(CNBC_AWAAZ_INTRO_MESSAGE);
      } else if (headersApi["App-Id"] === "fa6b02f6-0906-cnbc-11ef-poltu-ac73-ce427119df63") {
        initialMessagesFormatted.push(CNBC_TV18_INTRO_MESSAGE);
      } else {
        initialMessagesFormatted.push(LOK_SABHA_INTRO_MESSAGE);
      }
    } else {
      initialMessagesFormatted.push(LOK_SABHA_INTRO_MESSAGE);
    }
    
    initialMessagesFormatted.push(LOK_SABHA_INITIAL_QUESTION);
  }
  
  const { messages, appendMsg, setTyping } = useMessages(
    initialMessagesFormatted
  );
  
  const [val, setVal] = useState("");
  const [showChat, setShowChat] = useState(false);
 
  const navStyle = {
    font: "normal normal bold 22px/27px Montserrat",
    margin: "0px",
    color: HEADING_TEXT_COLOUR,
    opacity: "1",
    backgroundColor: "#EB3F2C",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.20)",
  };
 
  const crossStyle = {
    position: "absolute",
    color: "white",
    top: "8px",
    right: "5px",
  };
 
  // const headingStyle = {
  //   textAlign: "center",
  //   font: "normal normal bold 15px Montserrat",
  //   color: "#FFFFFF",
  //   fontSize: "11px",
  //   textShadow: "0px 3px 6px #00000029",
  //   opacity: "1",
  // };
 
  // const subHeadingStyle = {
  //   textAlign: "center",
  //   font: "normal normal bold 10px Montserrat",
  //   fontSize: "14px",
  //   letterSpacing: "1px",
  //   marginTop: "-2vh",
  //   color: "#FFFFFF",
  //   textShadow: "0px 3px 6px #00000029",
  //   opacity: "1",
  // };
 
  document.documentElement.style.setProperty("--brand-2", "#EB3F2C");
  document.documentElement.style.setProperty("--brand-1", "#EB3F2C");
  document.documentElement.style.setProperty("--bs-body-bg", "transparent");

  const navBarCustom = () => {
    let heading = HEADING;
    let headingColor = "#FFFFFF";
    let fontSize = "25px"; 
  
    switch (headersApi["App-Id"]) {
      case "2212199801122000":
        // This is for news 18
        break;
      case "fa6b02f6-0906-cnbc-11ef-poltu-ac73-ce427119df63":
        heading = "CNBC-TV18";
        navStyle.backgroundColor = "#043d68";
        if (window.matchMedia("(max-height: 350px)").matches) {
          // Adjust font size for CNBC-TV18 app when height is 350px or less
          fontSize = "22px";
        }
        break;
      case "ga9b02f6-2310-cnbc-awaaz-11ef-poltu-td73-ce427000df63":
        heading = "CNBC Awaaz";
        navStyle.backgroundColor = "#043d68";
        break;
      default:
        // Handle other cases if needed
        break;
    }
  
    return (
      <div style={navStyle} className="d-flex align-items-center">
        <img
          src={chatBotIcon}
          alt=""
          className=""
          width={window.outerWidth <= 400 ? "70" : "80"}
        />
        <div>
          <h3
            style={{
              fontFamily: "Montserrat",
              fontWeight: "bolder",
              fontSize: "9px", // Adjusted font size
              color: headingColor, // Use dynamic color
              letterSpacing: "0",
              lineHeight: "16px",
              marginBottom: "0px",
            }}
          >
            {heading} {/* Use dynamic heading */}
          </h3>
          <span
            style={{
              fontFamily: "Montserrat",
              fontSize: fontSize, // Adjusted font size
              color: headingColor, // Use dynamic color
              letterSpacing: "0",
              lineHeight: "22px",
              display: "block",
            }}
          >
            {headersApi && headersApi.Pathname && headersApi.Pathname === "/assembly" ? ASSEMBLY_HEADING : LOK_SABHA_HEADING}
          </span>
          <span
            style={{
              font: "normal normal 600 11px/14px Montserrat",
              fontSize: "11px",
              color: headingColor, // Use dynamic color
              letterSpacing: "0",
              display: "block",
              height: "14px",
              width: "31px",
              textShadow: "0px 3px 6px #00000029",
              opacity: "1",
            }}
          >
            {HEADING3}
          </span>
        </div>
      </div>
    );
  };
  
  
 
  const apiCall = async (data) => {
    if (apiInProgress) {
      return Promise.resolve();
    }
 
    setApiInProgress(true);
    if (
      headersApi &&
      headersApi["App-Id"] &&
      headersApi["App-Id"] === "fullPage"
    ) {
      headersApi["App-Id"] = "2212199801122000";
    }
 
    try {
      const options = {
        method: "POST",
        url: urlApi,
        data: {questions: data},
        headers: headersApi,
      };
      const response = await axios.request(options);
 
      return response;
    } catch (e) {
      /* empty */
    } finally {
      setApiInProgress(false);
    }
  };
 
  const checkGreetings = (message) => {
    let isGreeting = false;
 
    if (message.length < 500) {
      for (let x = 0; x < GREETINGS.length; x++) {
        if (message.trim().toLowerCase() === GREETINGS[x]) {
          isGreeting = true;
          break;
        }
      }
    } else {
      isGreeting = true;
    }
 
    return isGreeting;
  };
  
  useEffect(() => {
    // Show the first bubble after 1 second
    const firstBubbleTimer = setTimeout(() => {
      setShowFirstBubble(true);
    }, 1000);

    // Show the first bubble's text after 2 seconds
    const firstTextTimer = setTimeout(() => {
      setShowFirstText(true);
    }, 2000);

    // Show the second bubble after the first bubble's text is shown
    const secondBubbleTimer = setTimeout(() => {
      setShowSecondBubble(true);
    }, 3000);

    // Show the second bubble's text after a further delay
    const secondTextTimer = setTimeout(() => {
      setShowSecondText(true);
    }, 5000);

    // Show the close button after the second bubble's text is shown
    const closeButtonTimer = setTimeout(() => {
      setShowCloseButton(true);
    }, 5000);

    return () => {
      clearTimeout(firstBubbleTimer);
      clearTimeout(firstTextTimer);
      clearTimeout(secondBubbleTimer);
      clearTimeout(secondTextTimer);
      clearTimeout(closeButtonTimer);
    };
  }, []);

  const handleCloseBubbles = () => {
    setAreBubblesVisible(false);
  };
  useEffect(() => {
    if (
      headersApi &&
      headersApi["App-Id"] &&
      headersApi["App-Id"] === "fullPage"
    ) {
      setShowChat(true);
    }
 
    const handleTextareaKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "a") {
        return;
      }
 
      const textarea = document.querySelector(".Composer-input");
 
      if (textarea && textarea.value.length >= 500 && e.key !== "Backspace") {
        e.preventDefault();
      }
    };
 
    const handlePaste = (e) => {
      const pastedText = (e.clipboardData || window.clipboardData).getData(
        "text"
      );
 
      const textarea = document.querySelector(".Composer-input");
 
      if (textarea && pastedText.length >= 500) {
        e.preventDefault();
        const truncatedText = pastedText.substring(0, 500);
        document.execCommand("insertText", false, truncatedText);
      }
    };
 
    const textarea = document.querySelector(".Composer-input");
 
    if (textarea) {
      textarea.addEventListener("keydown", handleTextareaKeyDown);
      textarea.addEventListener("paste", handlePaste);
      textarea.style.overflow = "auto";
    }
 
    return () => {
      const textarea = document.querySelector(".Composer-input");
 
      if (textarea) {
        textarea.removeEventListener("keydown", handleTextareaKeyDown);
        textarea.removeEventListener("paste", handlePaste);
      }
    };
  }, []);
 
 
  useEffect(() => {
    if (sampleText !== "") {
      const textarea = document.querySelector(".Composer-input");
      textarea.value = sampleText;
    }
 
    return () => {
      if (sampleText !== "") {
        const textarea = document.querySelector(".Composer-input");
        textarea.value = sampleText;
      }
    };
  }, [waitingForResponse, sampleText]);
 
  const handleFilter = useCallback(
    async (type, val) => {
      if (apiInProgress) {
        const textarea = document.querySelector(".Composer-input");
        if (
          global?.event?.target?.value &&
          val !== global?.event?.target?.value
        ) {
          val = global?.event?.target?.value;
        }
 
        textarea.value = val;
        // cRef.current.setText(sampleText);
        setSampleText(val);
        return;
      } else {
        await handleSend(type, val);
        setSampleText("");
      }
    },
    [apiInProgress]
  );
 
  useEffect(() => {
    return () => {
      if (waitingForResponse) {
        let sendClass = document.getElementsByClassName("Composer-sendBtn");
        if (sendClass && sendClass.length) {
          sendClass[0].disabled = false;
        }
        if (sampleText !== "") {
          // Additional cleanup logic if needed
        }
      }
    };
  }, [waitingForResponse]);
 
  const handleSend = async (type, val) => {
    try {
      setWaitingForResponse(true);
 
      const textarea = document.querySelector(".Composer-input");
 
      if (textarea && textarea.value !== sampleText && val === "") {
        val = textarea.value;
      }
      if (type === "text" && val.trim()) {
        appendMsg({
          type: "text",
          content: { text: val },
          position: "right",
        });
 
        if (checkGreetings(val)) {
          appendMsg({
            type: "text",
            content: { text: GREETING_MESSAGE },
            position: "left",
          });
          return setWaitingForResponse(false);
        }
 
        setTyping(true);
 
        let storedQuestionList = sessionStorage.getItem("questionsList");
        storedQuestionList = JSON.parse(storedQuestionList);
 
        sessionStorage.setItem("questionsList", JSON.stringify(val));
 
        if (!storedQuestionList.length) {
          storedQuestionList = val;
        } else {
          storedQuestionList = val;
        }
 
        const apiRes = await apiCall(storedQuestionList);
        let reply = "";
 
        if (apiRes?.data?.answer) {
          const previousSessionId = sessionStorage.getItem("previousSessionId");
          const currentSessionId = sessionId;
          if (currentSessionId !== previousSessionId) {
            ReactGA.event({
              category: "Session",
              action: "firstConversation",
              label: currentSessionId,
            });
            sessionStorage.setItem("previousSessionId", currentSessionId);
          }
          ReactGA.event({
            category: "Button Click",
            action: "netSuccessfullBotResponse",
            label: "conversation",
          });
          reply = apiRes.data.answer;
        } else {
          ReactGA.event({
            category: "Button Click",
            action: "netFailedBotResponse",
            label: "conversation",
          });
          reply = REPLY_MESSAGE;
        }
 
        appendMsg({
          type: "text",
          content: { text: reply },
        });
      }
    } catch (e) {
      /* empty */
    } finally {
      setWaitingForResponse(false);
    }
  };
 
  const renderMessageContent = (msg) => {
    const { type, content, position } = msg;
 
    const userImage = position === "right" ? user : headersApi["App-Id"] === "fa6b02f6-0906-cnbc-11ef-poltu-ac73-ce427119df63" ? user3 : headersApi["App-Id"] === "ga9b02f6-2310-cnbc-awaaz-11ef-poltu-td73-ce427000df63" ? user3 : user2;
 
    const iconHeight = "22px";
 
    const userIconStyle = {
      height: iconHeight,
      width: "22px",
      marginRight: "8px",
      marginTop: "1px",
    };
    const userIconStyle2 = {
      height: iconHeight,
      width: "22px",
      marginRight: "0px",
    };
 
    const optionsStyle = {
      position: "relative",
      color: "#0082E2",
      cursor: waitingForResponse ? "not-allowed" : "pointer",
      marginBottom: "8px",
      display: "block",
      boxShadow: "0px 1px 3px #00000029",
      opacity: "1",
      borderRadius: "6px",
      padding: "8px",
    };
 
    const options2Style = {
      position: "relative",
      color: "#4A4A4A",
      marginBottom: "8px",
      display: "block",
      boxShadow: "0px 1px 3px #00000029",
      opacity: "1",
      borderRadius: "6px",
      padding: "8px",
    };
 
    if (type === "options" || type === "options2") {
      let containerStyle;
      if (type === "options") {
        containerStyle = {
          marginTop: "-11px",
        };
      } else if (type === "options2") {
        containerStyle = {
          marginTop: "0px",
        };
      }
 
      return (
        <div style={containerStyle}>
          {content.options.map((option, index) => (
            <div
              key={index}
              style={{ display: "flex", alignItems: "flex-start" }}
            >
              {position === "left" && (
                <img
                  src={userImage}
                  alt=""
                  className={chatstyle.userImage}
                  style={userIconStyle}
                />
              )}
              <Bubble
                content={option.text}
                onClick={() => handleOptionClick(option.value)}
                style={type === "options2" ? options2Style : optionsStyle}
              >
                {position === "left" && (
                  <div className={chatstyle.arrowLeft}></div>
                )}
                {position === "right" && <div className="arrowRight"></div>}
              </Bubble>
              {position === "right" && (
                <img
                  src={userImage}
                  alt=""
                  className={chatstyle.userImage}
                  style={userIconStyle}
                />
              )}
            </div>
          ))}
        </div>
      );
    }
 
    return (
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        {position === "left" && (
          <img
            src={userImage}
            alt=""
            className={chatstyle.userImage}
            style={userIconStyle}
          />
        )}
        <Bubble
          content={content.text}
          style={{
            position: "relative",
            background: position === "right" ? "#0F203F" : "#FFFFFF",
            color: position === "right" ? "#FFFFFF" : "#4A4A4A",
            borderRadius: "6px",
            padding: "9px",
            textAlign: position === "right" ? "left" : "left",
            boxShadow: "0px 1px 3px #00000029",
            opacity: "1",
            marginRight: "8px",
          }}
        >
          {position === "left" && <div className={chatstyle.arrowLeft}></div>}
          {position === "right" && <div className={chatstyle.arrowRight}></div>}
        </Bubble>
        {position === "right" && (
          <img
            src={userImage}
            alt=""
            className={chatstyle.userImage}
            style={userIconStyle2}
          />
        )}
      </div>
    );
  };
 
  const handleOptionClick = async (value) => {
    try {
      if (waitingForResponse) {
        return;
      }
      setWaitingForResponse(true);
      // Call handleSend with the selected option value
      await handleFilter("text", value);
      setSampleText("");
      setWaitingForResponse(false);
    } catch (e) {
      setWaitingForResponse(false); // Reset the waiting state in case of an error
    }
  };
 
  useEffect(() => {
    sessionStorage.setItem("questionsList", JSON.stringify([]));
  }, []);
 
  useEffect(() => {
    return () => {
      let sendClass = document.getElementsByClassName("Composer-sendBtn");
      if (sendClass && sendClass.length) {
        sendClass[0].setAttribute("disabled", "true");
      }
    };
  }, [val]);
 
  useEffect(() => {
    let bottomPosition = window.outerWidth <= 768 ? "85px" : "0px";
    if (showChat) {
      let chatCss;
      switch (headersApi["App-Id"]) {
        case "fa6b02f6-0906-cnbc-11ef-poltu-ac73-ce427119df63":
          // For CNBC-TV18 app ID
          chatCss = `
            width: 90%;
            max-width: 380px;
            height: ${window.outerWidth <= 768 ? "350px" : "420px"};
            max-height: 680px;
            background: transparent !important; /* Make the background transparent */
            overflow: auto;
            display: block;
            position: fixed;
            opacity: 1;
            bottom: ${window.outerWidth<= 768 ? "160px" : "200px"};
            right: 10px;
            z-index: 9999;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          `;
          break;
        default:
          // For other app IDs
          chatCss = `
            border-radius: 10px;
            width: 90%;
            max-width: 380px;
            height: ${window.outerWidth <= 770 ? "80vh" : "90vh"};
            max-height: 680px;
            overflow: auto;
            display: block;
            position: fixed;
            border: 1px solid #D9D9D9;
            opacity: 1;
            bottom: ${bottomPosition};
            right: 10px;
            z-index: 9999;
            background-color: #fff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          `;
      }
    
      window.parent.postMessage({ type: "message", data: chatCss }, "*");
    } else {
      let chatCss;
      switch (headersApi["App-Id"]) {
        case "fa6b02f6-0906-cnbc-11ef-poltu-ac73-ce427119df63":
          // For CNBC-TV18 app ID
          chatCss = `
          // background: transparent linear-gradient(131deg, #DE457D 0%, #2E52C2 100%) 0% 0% no-repeat padding-box;
          // box-shadow: 0px 3px 6px #00000029;
          // border: 1px solid #FFFFFF;
          opacity: 1;
          // border-radius: 100px;
          display:block;
          transform: scale(0.8);
          width:250px;
          height:275px;
          position: fixed;
          bottom: ${bottomPosition};
          right: 0px;
          z-index: 9999;
          overflow: hidden !important; 
          `;
          break;
        default:
          // For other app IDs
          chatCss = `
            // background: transparent linear-gradient(131deg, #DE457D 0%, #2E52C2 100%) 0% 0% no-repeat padding-box;
            // box-shadow: 0px 3px 6px #00000029;
            // border: 1px solid #FFFFFF;
            opacity: 1;
            // border-radius: 100px;
            display:block;
            transform: scale(0.8);
            width:250px;
            height:275px;
            position: fixed;
            bottom: ${bottomPosition};
            right: 0px;
            z-index: 9999;
            overflow: hidden !important;          
            `;
      }
      window.parent.postMessage({ type: "message", data: chatCss }, "*");
    }
    
  }, [showChat]);
  
  const renderBeforeMessageList = () => {
    return (
      <div>
        <div
          style={{
            padding: "5px",
            textAlign: "center",
            color: "#676767",
            fontSize: "10px",
            position: "absolute",
            bottom: 0,
            width: "100%",
            zIndex: 2,
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        >
          {CHATBOT_BETA_MESSAGE}
        </div>
      </div>
    );
  };
 
  return (
    <div className={chatstyle.mainApp}>
      {!showChat ?<div className="">
      <div className="chat-container">
          {areBubblesVisible && (
            <>
              {showCloseButton && (
                <button className="close-button" onClick={handleCloseBubbles}>×</button>
              )}
              {showFirstBubble && (
                <div className={`chat-bubble-1 ${showSecondBubble ? "move-up" : ""}`}>
                  {showFirstText ? (
                    "Hello! I’m Poltu"
                  ) : (
                    <div className="dot-container">
                      <div className="dot dot1"></div>
                      <div className="dot dot2"></div>
                      <div className="dot dot3"></div>
                    </div>
                  )}
                </div>
              )}
              {showSecondBubble && (
                <div className="chat-bubble">
                  {showSecondText ? (
                    "Ask me about Elections"
                  ) : (
                    <div className="dot-container">
                      <div className="dot dot1"></div>
                      <div className="dot dot2"></div>
                      <div className="dot dot3"></div>
                    </div>
                  )}
                </div>
          )}

        </>
      )}
    </div>

        </div>:""}
     {!showChat ? (
<div
className={
   headersApi["App-Id"] === "fa6b02f6-0906-cnbc-11ef-poltu-ac73-ce427119df63"
     ? chatstyle.customBackground
     : headersApi["App-Id"] === "ga9b02f6-2310-cnbc-awaaz-11ef-poltu-td73-ce427000df63"
     ? chatstyle.customBackground
     : chatstyle.background
}
style={{
  //  height: "100vh",
   width: "100vw",
   border: "none",
   boxShadow: "none",
  //  overflow: "auto",
   textAlign: "center",
}}
onClick={() => setShowChat(true)}
>
<motion.div
          initial={{ opacity: 0, scale: 0.5 }} 
          animate={{ opacity: 1, scale: 1 }} 
          transition={{ duration: 1 }} 
        >
          <div className="">
            <img
              src={chatBotIcons}
              alt=""
              className=""
              style={{
                transform: "scale(0.8)",
                marginLeft: "158px",
                marginTop: "-43vh",
                overflow: "hidden",
                position: "fixed",
                height: "100px"
              }}
            />
          </div>
        </motion.div>
        </div>
      ) : (
        <div
          style={{ border: "none", " box-shadow": "none" }}
          onClick={() => setShowChat(false)}
        >
          <img
            src={cancelIcon}
            alt="chatIcon"
            className=""
            style={crossStyle}
            width="20"
          />
        </div>
      )}
      {showChat && (
        <motion.div
          className=" w-100"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.3,
            delay: 0,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <div style={{ height: "100vh", marginBottom: "none" }}>
            <Chat
              onInputChange={(e) => {
                if (waitingForResponse) {
                  let sendClass =
                    document.getElementsByClassName("Composer-sendBtn");
                  if (sendClass && sendClass.length) {
                    sendClass[0].setAttribute("disabled", "true");
                  } else {
                    setVal(e);
                  }
                }
              }}
              renderNavbar={navBarCustom}
              messages={messages}
              placeholder="Type here..."
              renderMessageContent={renderMessageContent}
              onSend={handleFilter}
              locale=""
              renderBeforeMessageList={renderBeforeMessageList}
            />
          </div>
        </motion.div>
      )}
    </div>
  );
};
export default ChatWidget;