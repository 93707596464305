import "./App.css";
import ChatWidget from "./components/chat";
import { APIURL, APPIDS } from "./Config/chatProps";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ReactGA from "react-ga4";

function App() {
    const [isValid, setIsValid] = useState(false);
    const [appIdProp, setAppIdProp] = useState("");
    const [uniqueId, setUniqueId] = useState("");
    const [guestId, setGuestId] = useState("");
    const [path, setPath] = useState("/");
    useEffect(() => {
        const initializeGA = () => {
            const searchParams = new URLSearchParams(window.location.search);
            const appId = searchParams.get("appId");
            let measurementId = "G-EHS0BS6X81";
            if(window.location.pathname === "/" && appId==="2212199801122000"){
                measurementId = "G-EHS0BS6X81";
                // LokSabha News18
            }
            else if ((window.location.pathname === "/assembly") || (window.location.pathname === "/assembly" && appId==="2212199801122000")){
                measurementId = "G-KW7LG3KCH5";
                // Assembly News18
            }
            else if(window.location.pathname === "/" && appId==="ga9b02f6-2310-cnbc-awaaz-11ef-poltu-td73-ce427000df63"){
                measurementId = "G-YDGJX8FJ2B";
                // Lok Sabha CNBC Awaaz
            }
            else if(window.location.pathname === "/" && appId==="fa6b02f6-0906-cnbc-11ef-poltu-ac73-ce427119df63"){
                measurementId = "G-JZK13J3WVG";
                // Lok Sabha CNCB-TV18
            }
            ReactGA.initialize(measurementId);
            ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "chatbotViewed" });
        };

        initializeGA();

        ReactGA.event({
            category: "Button Click",
            action: "chatbotInitiatedForNewSession",
            label: "poltuLandingPage",
          });
          let storedGuestId = localStorage.getItem("guestId");
        if (!storedGuestId) {
        storedGuestId = uuidv4();
        localStorage.setItem("guestId", storedGuestId);
        }
        setPath(window.location.pathname);
    setGuestId(storedGuestId);
    if (window?.location?.search) {
        const appIdQuery = window.location.search.split("?");
        if (appIdQuery && appIdQuery.length) {
            let appId = appIdQuery[1];
            if (appId.includes(appId)) {
                appId = appId.split("=")[1];
                APPIDS.forEach((i) => {
                    if (i.value === appId) {
                        setAppIdProp(appId);
                        setIsValid(true);
                    }
                });
            }
        }

    }else{
            setAppIdProp("fullPage");
            setIsValid(true);
        }
        const newUniqueId = uuidv4();
        setUniqueId(newUniqueId);
    }, []);
    return (!isValid ? <div className=''></div> :
        <div>
            <ChatWidget
                urlApi={APIURL}
                headersApi={{
                    "Content-Type": "application/json",
                    "App-Id": appIdProp,
                    "Session-Id": uniqueId,
                    "Guest-Id": guestId,
                    "Pathname": path
                }}
                sessionId={uniqueId}
                guestId={guestId}
            />
        </div>
    );
}

export default App;

