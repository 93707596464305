export const APIURL = "https://chatbot.news18.com/api/chat";

export const APPIDS = [
  {
    origin: "news18.com",
    value: "2212199801122000"
  },
  {
    origin: "CNBCTV18.com",
    value: "fa6b02f6-0906-cnbc-11ef-poltu-ac73-ce427119df63"
  },
  {
    origin: "CNBCAwaaz.com",
    value: "ga9b02f6-2310-cnbc-awaaz-11ef-poltu-td73-ce427000df63",
  }
];
